import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DomainService } from '../../services/domain.service';
import { DataBindingDirective, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
declare var Swal: any;

@Component({
    selector: 'app-domain-list',
    templateUrl: './domain-list.component.html'
})
export class DomainListComponent implements OnInit {

    domainList;
    total;

    pageSize: number = 10;
    allowUnsort = true;
    skip: number = 0;
    paginationEntity = {
        limit: this.pageSize,
        offset: 1,
        searchData: {
            status: '',
            searchQuery: ''
        },
        sortOrder: [{
            field: "dom_provider_id",
            dir: 'asc'
        }]
    };
    state = {
        skip: 0,
        take: this.pageSize
    };
    sort: SortDescriptor[] = [{
        field: 'dom_provider_id',
        dir: 'asc'
    }];

    constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private DomainService: DomainService) { }

    @ViewChild(DataBindingDirective, { static: false }) dataBinding: DataBindingDirective;

    ngOnInit(): void {
        let self = this;
        setTimeout(function () {
            self.globals.breadcrumbs = [self.route.snapshot.data['product'], 'Manage', 'Domains'];
        }, 100);
        this.globals.isLoading = true;
        this.getDomainData();
    }

    getDomainData() {
        this.DomainService.getAll(this.paginationEntity)
            .then((data: any) => {
                this.domainList = {
                    data: (data.count > 0) ? orderBy(data.data, this.sort) : '',
                    total: data.count,
                }
                this.globals.isLoading = false;
                this.skip = this.skip > this.domainList.total ? 0 : this.skip;
            },
                (error) => {
                    this.globals.isLoading = false;
                    this.globals.errorSweetAlert();
                });
    }

    updateStatus(dataItem,index) {
        let text; let ctext;
        if(this.domainList.data[index].is_active==true){
            text = 'activate';
            ctext = 'Activate';
        } else {
            text = 'deactivate';
            ctext = 'Deactivate';
        }
        Swal.fire({
            icon: 'warning',
            //title: 'Change Status of Domain' + ' - ' + dataItem.name,
            //text: 'Are you sure, you want to change the status of this domain?',
            title: ctext+' Domain' + ' - ' + dataItem.name,
            text: 'Are you sure, you want to '+text+' this domain?',            
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.globals.isLoading = true;
                this.DomainService.updateStatus(dataItem.id)
                .then((data) => {
                    this.globals.isLoading = false;
                    // this.globals.sweetAlert('success', 'Domain Status Updated', 'Domain status has been updated successfully.');
                    this.globals.sweetAlert('success', 'Domain '+ctext+'d', 'Domain '+text+'d successfully.');
                },
                (error) => {
                    this.globals.isLoading = false;
                    this.globals.errorSweetAlert();
                });
            } else {
                this.domainList.data[index].is_active = !this.domainList.data[index].is_active;
            }
        })
    }

    delete(dataItem) {
        Swal.fire({
            icon: 'warning',
            title: 'Delete Domain' + ' - ' + dataItem.name,
            text: 'Are you sure, you want to delete this domain?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.globals.isLoading = true;
                    this.DomainService.delete(dataItem.id)
                        .then((data) => {
                            this.globals.isLoading = false;
                            this.globals.sweetAlert('success', 'Domain Deleted', 'Domain has been deleted successfully.');
                            this.getDomainData();
                        },
                            (error) => {
                                this.globals.isLoading = false;
                                this.globals.errorSweetAlert();
                            });
                }
            })
    }

    edit(id) {
        this.router.navigate(['/' + this.globals.currentLink.split('/')[1] + '/configuration/domain/edit/' + id]);
    }

    // pageChange Event
    public pageChange(event: PageChangeEvent): void {
        this.skip = (this.pageSize == event.take) ? event.skip : ((this.skip < event.take) ? event.skip : this.skip);
        this.pageSize = event.take;
        this.paginationEntity.offset = Math.floor((this.skip / this.pageSize) + 1);
        this.paginationEntity.limit = event.take;
        this.getDomainData();
    }

    // sortOrder change Event
    public sortChange(sort: any): void {
        if (sort.dir != "undefined") {
            this.sort = sort;
            this.paginationEntity.sortOrder = [];
            this.paginationEntity.sortOrder = sort;
            this.getDomainData();
        }
    }

    // Filter event
    public onFilter(inputValue: string): void {
        if (inputValue != "" && inputValue.length > 2) {
            this.paginationEntity.offset = 1;
            this.paginationEntity.searchData.searchQuery = inputValue;
            this.getDomainData();
        } else if (inputValue == "") {
            this.paginationEntity.searchData.searchQuery = '';
            this.pageChange(this.state);
        }
    }

}