<div class="inner_content_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">
                    <h1 class="float-start">{{itemText.titlecase_plural}}</h1>
                    <a kendoButton
                        routerLink="/{{this.globals.currentLink.split('/')[1]}}/configuration/{{itemText.lowercase}}/add"
                        class="all_btn theme_btn float-end" *ngIf="globals.permissions['add-question']">Add
                        {{itemText.titlecase_with_article}}</a>
                    <div class="clearfix"></div>
                </div>
                <div class="advanced_search_block">
                    <form class="k-form" [formGroup]="filterForm">
                        <fieldset class="k-form-fieldset">
                            <h5 class="float-start">Advanced Search</h5>
                            <button class="float-end close close_search" type="button"
                                (click)="advanced_search_block()" ><span>×</span></button>
                            <div class="clearfix"></div>

                            <div class="row">
                                <div class="col-12 col-xxl-12 col-xl-12 col-lg-4 col-md-6 col-sm-12">
                                    <kendo-formfield>
                                        <label class="float-start">Domain</label>
                                        <button class="select_btn float-end" (click)="selectAll('domain')"
                                            *ngIf="filterForm.value.domain_id.length != domainListFilterData.length">Select
                                            all</button>
                                        <button class="select_btn float-end" (click)="clearAll('domain')"
                                            *ngIf="filterForm.value.domain_id.length == domainListFilterData.length">Clear
                                            all</button>
                                        <kendo-multiselect [data]="domainList" #domain_id formControlName="domain_id"
                                            [textField]="'name'" [valueField]="'id'" [autoClose]="false"
                                            [filterable]="true" [valuePrimitive]="true" 
                                            (filterChange)="domainFilter($event)" [itemDisabled]="itemDisabled"
                                            (valueChange)="getSubdomainsByDomian($event)">
                                            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                                <div class="check_box">
                                                    <input type="checkbox" class="k-checkbox"
                                                        [checked]="isDomainSelected(dataItem.id)">
                                                    <label
                                                        class="k-checkbox-label">{{dataItem.name}}&nbsp;({{dataItem.total_task}})</label>
                                                </div>
                                            </ng-template>
                                            <ng-template kendoMultiSelectTagTemplate let-dataItem>
                                                {{ dataItem.name }}&nbsp;({{ dataItem.total_task }})
                                            </ng-template>
                                            <ng-template kendoMultiSelectNoDataTemplate>
                                                <h6>NO DOMAIN FOUND</h6>
                                            </ng-template>
                                        </kendo-multiselect>
                                    </kendo-formfield>
                                </div>
                                <div class="col-12 col-xxl-12 col-xl-12 col-lg-4 col-md-6 col-sm-12">
                                    <kendo-formfield>
                                        <label class="float-start">Subdomain</label>
                                        <button class="select_btn float-end" (click)="selectAll('subdomain')"
                                            *ngIf="subdomainList.length>0 && filterForm.value.subdomain_id.length != subdomainListFilterData.length">Select
                                            all</button>
                                        <button class="select_btn float-end" (click)="clearAll('subdomain')"
                                            *ngIf="filterForm.value.subdomain_id.length>0 && filterForm.value.subdomain_id.length == subdomainListFilterData.length">Clear
                                            all</button>
                                        <kendo-multiselect [data]="subdomainList" #subdomain_id
                                            formControlName="subdomain_id" [textField]="'name'" [valueField]="'id'"
                                            [autoClose]="false" [filterable]="true" [valuePrimitive]="true"
                                            [itemDisabled]="itemDisabled" (filterChange)="subdomainFilter($event)">
                                            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                                <div class="check_box">
                                                    <input type="checkbox" class="k-checkbox"
                                                        [checked]="isSubDomainSelected(dataItem.id)">
                                                    <label
                                                        class="k-checkbox-label">{{dataItem.name}}&nbsp;({{dataItem.total_task}})</label>
                                                </div>
                                            </ng-template>
                                            <ng-template kendoMultiSelectTagTemplate let-dataItem>
                                                {{ dataItem.name }}&nbsp;({{ dataItem.total_task }})
                                            </ng-template>
                                            <ng-template *ngIf="filterForm.value.domain_id.length > 0" kendoMultiSelectNoDataTemplate>
                                                <h6>NO SUBDOMAIN FOUND</h6>
                                            </ng-template>

                                            <ng-template *ngIf="filterForm.value.domain_id.length <= 0" kendoMultiSelectNoDataTemplate>
                                                <h6>Select a Domain First</h6>
                                            </ng-template>
                                        </kendo-multiselect>
                                    </kendo-formfield>
                                </div>
                                <!-- <div class="col-12 col-xxl-12 col-xl-12 col-lg-4 col-md-6 col-sm-12">
                                    <kendo-formfield *ngIf="globals.currentModule == 'dcm'">
                                        <label class="float-start">Level</label>
                                        <button class="select_btn float-end" (click)="selectAll('level')"
                                            *ngIf="levelList.length>0 && filterForm.value.level_id.length != levelListFilterData.length">Select
                                            all</button>
                                        <button class="select_btn float-end" (click)="clearAll('level')"
                                            *ngIf="filterForm.value.level_id.length>0 && filterForm.value.level_id.length == levelListFilterData.length">Clear
                                            all</button>
                                        <kendo-multiselect [data]="levelList" #level_id formControlName="level_id"
                                            [textField]="'display_text'" [valueField]="'id'" [autoClose]="false"
                                            [filterable]="true" [valuePrimitive]="true" [itemDisabled]="itemDisabled"
                                            (filterChange)="levelFilter($event)">
                                            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                                <div class="check_box">
                                                    <input type="checkbox" class="k-checkbox"
                                                        [checked]="isLevelSelected(dataItem.id)">
                                                    <label
                                                        class="k-checkbox-label">{{dataItem.display_text}}&nbsp;({{dataItem.total_task}})</label>
                                                </div>
                                            </ng-template>
                                            <ng-template kendoMultiSelectTagTemplate let-dataItem>
                                                {{ dataItem.display_text }}&nbsp;({{ dataItem.total_task }})
                                            </ng-template>
                                            <ng-template kendoMultiSelectNoDataTemplate>
                                                <h6>NO LEVEL FOUND</h6>
                                            </ng-template>
                                        </kendo-multiselect>
                                    </kendo-formfield>
                                </div> -->
                                <div class="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div class="text-center mb-4">
                                        <button kendoButton [primary]="true" class="all_btn themeoption_btn"
                                            (click)="resetFilter()">
                                            Clear
                                        </button>
                                        <button kendoButton type="submit" [primary]="true" class="all_btn theme_btn"
                                            (click)="filterData()">Search</button>

                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div class="search_main_block">
                    <div class="filter_block">
                        <ul>
                            <li class="grey" *ngIf="globals.permissions['edit-question']"><i class="fa fa-pencil"></i>Edit</li>
                            <!-- <li class="red" *ngIf="globals.permissions['delete-question']"><i class="fa fa-trash"></i>Delete</li>
                            <li class="blue"><i class="fa fa-list-ul"></i>Interpretation</li> -->
                        </ul>
                    </div>
                     <div class="clearfix"></div>
                    <kendo-grid [data]="itemList" [pageSize]="pageSize" [skip]="skip" [pageable]="true"
                        (pageChange)="pageChange($event)" [sort]="sort" [sortable]="{
                            allowUnsort: false,
                            mode: 'single'
                            }" (sortChange)="sortChange($event)" scrollable="none">
                        <kendo-grid-messages pagerItems="{{itemText.titlecase_plural}}"
                            pagerItemsPerPage="{{itemText.titlecase_plural}} per page"
                            noRecords="No {{itemText.lowercase}} available.">
                        </kendo-grid-messages>
                        <ng-template kendoGridToolbarTemplate >
                            <input placeholder="Search by domain, subdomain, or {{itemText.lowercase}}" kendoTextBox
                                (input)="onFilter($event.target.value)" />
                            <button kendoButton kendoTooltip title="Advanced Search" position="top"
                                class="theme_btn all_btn adv_btn" (click)="advanced_search_block()"><i
                                    class="fa fa-filter"></i></button>
                            <kendo-pager-page-sizes [pageSizes]="globals.pagesizes"></kendo-pager-page-sizes>
                            <div class="clearfix"></div>
                            <!-- filter search criteria -->
                            <p class="search_result_text"
                                *ngIf="searchFilterResult.domain.length > 0 || searchFilterResult.subdomain.length > 0 || searchFilterResult.level.length > 0">
                                {{itemList.total}}
                                <span *ngIf="itemList.total > 1"
                                    class="result_title">{{itemText.titlecase_plural}}</span>
                                <span *ngIf="itemList.total <= 1" class="result_title">{{itemText.titlecase}}</span>
                                found for
                                <!-- domain -->
                                <span class="label_filter" kendoTooltip *ngIf="searchFilterResult.domain.length > 0">
                                    <b>Domain:</b>
                                    <span
                                        *ngIf="searchFilterResult.domain.length <= globals.maximumSearchCriteriaDisplay && searchFilterResult.domain.length < domainListFilterData.length">
                                        <span class="inner_filter"
                                            *ngFor="let domain of searchFilterResult.domain;">{{domain.name}}<button
                                                title="Remove {{domain.name}}"
                                                (click)="clearFilterElement('domain',domain.id)"><i
                                                    class="fa fa-times"></i></button></span>
                                    </span>
                                    <span
                                        *ngIf="searchFilterResult.domain.length > globals.maximumSearchCriteriaDisplay && searchFilterResult.domain.length < domainListFilterData.length">{{searchFilterResult.domain.length}}
                                        Selected</span>
                                    <span *ngIf="searchFilterResult.domain.length == domainListFilterData.length">All
                                        Selected</span>
                                    <button title="Clear Domain" (click)="clearFilterElement('domain')"><i
                                            class="fa fa-times"></i></button>
                                </span>
                                <!-- subdomain -->
                                <span class="label_filter" kendoTooltip *ngIf="searchFilterResult.subdomain.length > 0">
                                    <b>Subdomain:</b>
                                    <span
                                        *ngIf="searchFilterResult.subdomain.length <= globals.maximumSearchCriteriaDisplay && searchFilterResult.subdomain.length < subdomainListFilterData.length">
                                        <span class="inner_filter"
                                            *ngFor="let subdomain of searchFilterResult.subdomain;">{{subdomain.name}}<button
                                                title="Remove {{subdomain.name}}"
                                                (click)="clearFilterElement('subdomain',subdomain.id)"><i
                                                    class="fa fa-times"></i></button></span>
                                    </span>
                                    <span
                                        *ngIf="searchFilterResult.subdomain.length > globals.maximumSearchCriteriaDisplay && searchFilterResult.subdomain.length < subdomainListFilterData.length">{{searchFilterResult.subdomain.length}}
                                        Selected</span>
                                    <span
                                        *ngIf="searchFilterResult.subdomain.length == subdomainListFilterData.length">All
                                        Selected</span>
                                    <button title="Clear subdomain" (click)="clearFilterElement('subdomain')"><i
                                            class="fa fa-times"></i></button>
                                </span>
                                <!-- level -->
                                <span class="label_filter" kendoTooltip *ngIf="searchFilterResult.level.length > 0">
                                    <b>Level:</b>
                                    <span
                                        *ngIf="searchFilterResult.level.length <= globals.maximumSearchCriteriaDisplay && searchFilterResult.level.length < levelListFilterData.length">
                                        <span class="inner_filter"
                                            *ngFor="let level of searchFilterResult.level;">{{level.display_text}}<button
                                                title="Remove {{level.display_text}}"
                                                (click)="clearFilterElement('level',level.id)"><i
                                                    class="fa fa-times"></i></button></span>
                                    </span>
                                    <span
                                        *ngIf="searchFilterResult.level.length > globals.maximumSearchCriteriaDisplay && searchFilterResult.level.length < levelListFilterData.length">{{searchFilterResult.level.length}}
                                        Selected</span>
                                    <span *ngIf="searchFilterResult.level.length == levelListFilterData.length">All
                                        Selected</span>
                                    <button title="Clear level" (click)="clearFilterElement('level')"><i
                                            class="fa fa-times"></i></button>
                                </span>
                            </p>
                        </ng-template>
                        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                            <kendo-pager-prev-buttons  *ngIf="itemList.total > 0"></kendo-pager-prev-buttons>
                            <kendo-pager-numeric-buttons [buttonCount]="globals.pageIndex"  *ngIf="itemList.total > 0">
                            </kendo-pager-numeric-buttons>
                            <kendo-pager-next-buttons  *ngIf="itemList.total > 0"></kendo-pager-next-buttons>
                            <kendo-pager-info  *ngIf="itemList.total > 0"></kendo-pager-info>
                        </ng-template>
                        <kendo-grid-column field="question_provider_id" title="ID"
                            [headerStyle]="{width: '5%','text-align': 'center'}" [style]="{width: '5%'}"
                            class="text-center" media="(min-width: 769px)" class="text-center">
                        </kendo-grid-column>
                        <kendo-grid-column field="competency" title="Competency" [headerStyle]="{width: '15%'}"
                            [style]="{width: '15%'}" media="(min-width: 769px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="domain" title="Domain" [headerStyle]="{width: '20%'}"
                            [style]="{width: '20%'}" media="(min-width: 769px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="subdomain" title="Subdomain" [headerStyle]="{width: '20%'}"
                            [style]="{width: '20%'}" media="(min-width: 769px)">
                        </kendo-grid-column>
                        <kendo-grid-column *ngIf="globals.currentModule == 'dcm'" field="question_type" title="Level"
                            [headerStyle]="{width: '10%'}" [style]="{width: '10%'}" media="(min-width: 769px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="question_text" title="{{itemText.titlecase}}"
                            [headerStyle]="{width: '25%'}" [style]="{width: '25%'}" media="(min-width: 769px)">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.question_text != "" ? dataItem.question_text : '-'}}
                                </ng-template>
                                <!-- <div [innerHtml]="(dataItem.question_text.length>200) ? (dataItem.question_text | slice:0:200)+'...':(dataItem.question_text)">
                                  </div> -->
                                <div [innerHtml]="dataItem.question_text">
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="is_active" title="Is Active?"
                            [headerStyle]="{width: '10%','text-align': 'center'}" [style]="{width: '10%'}"
                            media="(min-width: 769px)" [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-question']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <!-- <kendo-switch id="test{{rowIndex}}" (valueChange)="updateStatus(dataItem,rowIndex)" [(ngModel)]="dataItem.is_active"
                                    [checked]="dataItem.is_active==1" [onLabel]="'Yes'" [offLabel]="'No'">
                                </kendo-switch> -->
                                {{dataItem.is_active==1 ? 'Yes' : 'No'}}
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Action" [headerStyle]="{width: '10%','text-align': 'center'}"
                            [style]="{width: '10%'}" media="(min-width: 769px)" [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-question'] || globals.permissions['delete-question']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip>
                                    <button (click)="edit(dataItem.id)" title="Edit" class="icon_btn grey"
                                        *ngIf="globals.permissions['edit-question']"><i
                                            class="fa fa-pencil"></i></button>
                                    <!-- <button kendoButton
                                        routerLink="/{{this.globals.currentLink.split('/')[1]}}/configuration/{{itemText.lowercase}}/interpretation/{{dataItem.id}}"
                                        title="Interpretation" [iconClass]="'k-icon'"
                                        class="icon_btn blue" *ngIf="globals.currentModule == 'dcm' && globals.permissions['edit-question']"><i class="fa fa-list-ul"></i></button> -->
                                    <button kendoButton (click)="delete(dataItem)" title="Delete" class="icon_btn red"
                                        [iconClass]="'k-icon k-i-delete'"
                                        *ngIf="globals.permissions['delete-question']"></button>

                                </div>
                            </ng-template>
                        </kendo-grid-column>


                        <kendo-grid-column field="question_provider_id" title="ID"
                            [headerStyle]="{width: '60px','text-align': 'center'}" [style]="{width: '60px'}"
                            class="text-center" media="(max-width: 768px)" class="text-center">
                        </kendo-grid-column>
                        <kendo-grid-column field="competency" title="Competency"
                            [headerStyle]="{width: '100px'}" [style]="{width: '100px'}"
                            media="(max-width: 768px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="domain" title="Domain" [headerStyle]="{width: '250px'}"
                            [style]="{width: '250px'}" media="(max-width: 768px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="subdomain" title="Subdomain" [headerStyle]="{width: '250px'}"
                            [style]="{width: '250px'}" media="(max-width: 768px)">
                        </kendo-grid-column>
                        <kendo-grid-column *ngIf="globals.currentModule == 'dcm'" field="question_type" title="Level"
                            [headerStyle]="{width: '150px'}" [style]="{width: '150px'}" media="(max-width: 768px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="question_text" title="{{itemText.titlecase}}"
                            [headerStyle]="{width: '300px'}" [style]="{width: '300px'}" media="(max-width: 768px)">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.question_text != "" ? dataItem.question_text : '-'}}
                                </ng-template>
                                <!-- <div [innerHtml]="(dataItem.question_text.length>200) ? (dataItem.question_text | slice:0:200)+'...':(dataItem.question_text)">
                              </div> -->
                                <div [innerHtml]="dataItem.question_text">
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="is_active" title="Is Active?" [style]="{width: '100px'}"
                            [headerStyle]="{width: '100px','text-align': 'center'}" media="(max-width: 768px)"
                            [sortable]="false" class="text-center" *ngIf="globals.permissions['edit-question']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <!-- <kendo-switch id="test{{rowIndex}}" (valueChange)="updateStatus(dataItem,rowIndex)" [(ngModel)]="dataItem.is_active"
                                [checked]="dataItem.is_active==1" [onLabel]="'Yes'" [offLabel]="'No'">
                            </kendo-switch> -->
                                {{dataItem.is_active==1 ? 'Yes' : 'No'}}
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Action" [style]="{width: '100px'}"
                            [headerStyle]="{width: '100px','text-align': 'center'}" media="(max-width: 768px)"
                            [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-question'] || globals.permissions['delete-question']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip>
                                    <button (click)="edit(dataItem.id)" title="Edit" class="icon_btn grey"
                                        *ngIf="globals.permissions['edit-question']"><i
                                            class="fa fa-pencil"></i></button>
                                    <!-- <button kendoButton
                                    routerLink="/{{this.globals.currentLink.split('/')[1]}}/configuration/{{itemText.lowercase}}/interpretation/{{dataItem.id}}"
                                    title="Interpretation" [iconClass]="'k-icon'"
                                    class="icon_btn blue" *ngIf="globals.currentModule == 'dcm' && globals.permissions['edit-question']"><i class="fa fa-list-ul"></i></button> -->
                                    <button kendoButton (click)="delete(dataItem)" title="Delete" class="icon_btn red"
                                        [iconClass]="'k-icon k-i-delete'"
                                        *ngIf="globals.permissions['delete-question']"></button>

                                </div>
                            </ng-template>
                        </kendo-grid-column>

                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>