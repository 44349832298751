import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
declare var $, Swal: any;
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  ip=null;

  constructor(public globals: Globals, private router: Router, private AuthService: AuthService, 
    private formBuilder: UntypedFormBuilder, private http: HttpClient, private route: ActivatedRoute, private CookieService: CookieService) { }

  ngOnInit(): void {
    
    //this.onClickMe();
    // localStorage.removeItem('auth_user');

    this.CookieService.delete('auth_user','/', this.globals.CookieDomainUrl);
    this.CookieService.delete('expires_at','/', this.globals.CookieDomainUrl);
    this.CookieService.delete('access_token','/', this.globals.CookieDomainUrl);
    this.CookieService.delete('user_token', '/', this.globals.CookieDomainUrl);
    this.CookieService.delete('token_type','/', this.globals.CookieDomainUrl);
    this.CookieService.delete('institute_details','/', this.globals.CookieDomainUrl);
    this.CookieService.delete('current_role_value','/', this.globals.CookieDomainUrl);

    localStorage.removeItem('last_updated_date_role_permission');
    localStorage.removeItem('all_role_permission');
    localStorage.removeItem('user_role_permission');

    // this.AuthService.getIP()
    //   .then((data) => {
    //     this.ip = data['ip'];
    //   }, (error) => {
    //     this.globals.isLoading = false;
    //     // this.globals.errorSweetAlert();
    // });

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,4}$')]],
      password: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_@$*&!#\-]{8,50}')]],
      ip_address:[this.ip],
      user_agent: [navigator.userAgent]
    });
    this.globals.isLoading = false;

    $("#password-show").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash")
      if ($("#password").attr("type") == "password") {
        $("#password").attr("type", "text");
      }
      else {
        $("#password").attr("type", "password");
      }
    });
  }

  login() { 
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      this.globals.isLoading = true;
      this.loginForm.controls['ip_address'].patchValue(this.ip);
      this.AuthService.checkOESSAdminLogin(this.loginForm.value)
        .then((data) => {
          this.AuthService.getAuthUser()
            .then((data) => {
              //this.globals.isLoading = false;
              if (this.globals.authData.list.length > 1) {
                let check = 0; let inactive_check = 0; let total_check = 0; let active_check = 0; let index = 0;
                for(let i=0; i<this.globals.authData.list.length; i++){
                  if(this.globals.authData.list[i].role_value==1 || this.globals.authData.list[i].role_value==2){
                      check++;
                      this.globals.authData.list[i].index_number = i;
                      this.CookieService.set('institute_details', window.btoa(JSON.stringify(this.globals.authData.list[i])), 365, '/', this.globals.CookieDomainUrl);
                      this.globals.institute_details = this.globals.authData.list[i];
                      //this.router.navigate(['/dashboard']);
                      window.location.href = this.globals.baseUrl+'/dashboard';
                      break;                
                  }
                  if(this.globals.authData.list[i].institute!=""){
                    if(this.globals.authData.list[i].institute.is_active==0){
                      inactive_check++;
                    } else {
                      index = i;
                      active_check++;
                    }
                    total_check++;
                  }                  
                }  
                if(active_check == 1 && total_check==this.globals.authData.list.length){
                    let i =index;
                    this.globals.authData.list[i].index_number = i;
                    this.CookieService.set('institute_details', window.btoa(JSON.stringify(this.globals.authData.list[i])), 365, '/', this.globals.CookieDomainUrl);
                    this.globals.institute_details = this.globals.authData.list[i];
                    window.location.href = this.globals.baseUrl+'/dashboard';
                } else {
                  if(inactive_check==total_check){
                    this.AuthService.logout()
                    .then((data) => { 
                      this.globals.isLoading = false;       
                      window.location.href = this.globals.baseUrl+'/login';
                    },
                    (error) => {
                      this.globals.isLoading = false;
                      this.globals.errorSweetAlert();
                    });
                  }  else if(check==0){
                    this.globals.institute_details = null;
                    localStorage.removeItem('institute_details');
                    this.router.navigate(['/landing-dashboard']);
                  }
                }                
              } else if (this.globals.authData.list.length == 1) {
                if(this.globals.authData.list[0].institute!="" && this.globals.authData.list[0].institute.is_active==0){
                  this.AuthService.logout()
                  .then((data) => { 
                    this.globals.isLoading = false;       
                    window.location.href = this.globals.baseUrl+'/admin/login';
                  },
                  (error) => {
                    this.globals.isLoading = false;
                    this.globals.errorSweetAlert();
                  });
                } else {
                  this.globals.authData.list[0].index_number = 0;
                  this.CookieService.set('institute_details', window.btoa(JSON.stringify(this.globals.authData.list[0])), 365, '/', this.globals.CookieDomainUrl);
                  this.globals.institute_details = this.globals.authData.list[0];
                  window.location.href = this.globals.baseUrl+'/dashboard';
                }                
              } else if(this.globals.authData.list.length == 0) {
                this.AuthService.logout()
                .then((data) => { 
                  this.globals.isLoading = false;       
                  window.location.href = this.globals.baseUrl+'/admin/login';
                },
                (error) => {
                  this.globals.isLoading = false;
                  this.globals.errorSweetAlert();
                });
              }
            }, (error) => {
              this.globals.isLoading = false;
              this.globals.errorSweetAlert();
          });
        },
        (error) => {
          this.globals.isLoading = false;
          if (error.status == 400) {
            this.globals.sweetAlert('warning','Deactive','Your account is deactive. so you cannot login.');
          } else if (error.status == 422 || error.status == 401) {
            this.globals.sweetAlert('warning','Invalid Login Credentials','Either email or password is incorrect.');
          } else {
            this.globals.errorSweetAlert();
          }
        });
    }
  }

   onClickMe(){ 
      const body = '<?xml version="1.0" encoding="utf-8"?> <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"xmlns:ns="http://www.avectra.com/2005/"><soapenv:Header/><soapenv:Body><ns:Authenticate><ns:userName>OpenEyes</ns:userName><ns:password>oeComp2020?!</ns:password></ns:Authenticate></soapenv:Body></soapenv:Envelope>';
      const url = "https://my.amsn.org/xweb/secure/netforumxml.asmx";

      this.http.post(url, body, {
          headers: new HttpHeaders()
          .set('Content-Type', 'text/xml') 
          .set('Accept', 'text/xml') 
          .append('SOAPAction', 'http://www.avectra.com/2005/Authenticate')
          //.append('Accept ', 'text/xml')
          .append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS') 
          .append('Access-Control-Allow-Origin', '*')
          .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method")
          , responseType:'text'    
      }).subscribe(data => {
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
        }
      }
    );
  }

}
